.backdrop {
  min-width: calc(100vw / var(--zoom));
  min-height: calc(100vh / var(--zoom));
}

.dialog {
  max-width: 1100px;
}

.ViewerNotesModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 25px;
  height: 700px;
  padding: 60px 60px 40px;

  .notesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .notes {
      width: 100%;
      height: 474px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      overflow-y: auto;
      background-color: #f5f6f7;
      box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);
      border-radius: 20px;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
