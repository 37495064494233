@import '../../assets/styles/mixins';

.SplitTemplate {
  min-height: calc(100vh / var(--zoom));
  display: flex;

  .rightSide {
    width: 50%;
    padding: 40px 80px;
    padding-left: 156px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    @include onMobile {
      width: 100%;
      // padding: 40px 36px;
      padding-top: 45px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 45px;
    }
  }

  .leftSide {
    width: 50%;
    display: flex;
    justify-content: center;
    position: relative;
    background-image: url(../../assets/images/auth/background.jpg);
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.55);
    background-blend-mode: multiply;

    @include onMobile {
      display: none;
    }

    img {
      max-height: calc(100vh / var(--zoom));
      width: 30%;
    }
  }
}
