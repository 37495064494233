@import '../../assets/styles/variables';

.dialog {
  max-width: 937px;
}

.CreateQuestionModal {
  padding-top: 36px;
  padding-left: 59px;
  padding-right: 52px;
  padding-bottom: 33px;
  position: relative;
  border-radius: 15px;
  background-color: white;

  .closeIcon {
    position: absolute;
    top: 58px;
    right: 59px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 34px;
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;
      text-align: left;
      color: var(--black);
    }

    .form {
      width: 100%;

      .label {
        display: flex;
        align-items: center;
        gap: 9px;
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        color: var(--black);
      }

      .questionContainer {
        position: relative;
        position: relative;

        textarea {
          width: 100%;
          height: 172px;
          padding: 16px 27px;
          font-size: 22px;
          line-height: 33px;
          border: 1px solid #666666;
          border-radius: 20px;
          resize: none;

          &::placeholder {
            color: rgba(17, 17, 17, 0.4);
          }

          &:focus {
            outline: none;

            &::placeholder {
              color: transparent;
            }
          }
        }
      }

      .optionsContainer {
        padding-top: 20px;
        padding-left: 16px;
        display: flex;
        align-self: flex-start;
      }
    }

    .footer {
      width: 100%;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
