@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.Question {
  width: 100%;
  padding-top: 34px;
  padding-left: 44px;
  padding-right: 44px;
  padding-bottom: 42px;
  background-color: #f8f8f8;
  border-radius: 18px;

  @include onMobile {
    padding-top: 27px;
    padding-left: 23px;
    padding-right: 22px;
    padding-bottom: 34px;
  }

  .questionContainer {
    display: flex;
    flex-direction: column;

    .responseCount {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      text-align: left;
      color: var(--black);

      @include onMobile {
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
      }
    }

    p {
      margin-bottom: 26px;
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      columns: var(--black);

      @include onMobile {
        margin-bottom: 14px;
        font-size: 24px;
        line-height: 33px;
      }
    }
  }

  .answers {
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding-top: 48px;

    @include onMobile {
      padding-top: 24px;
    }
  }
}
