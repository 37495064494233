@import '../../assets/styles/variables';

.dialog {
  max-width: 937px;
}

.backdrop {
  background-color: var(--black) !important;
  opacity: 0.7 !important;
}

.SelectShareOptionModal {
  position: relative;
  border-radius: 15px;

  .closeIcon {
    position: absolute;
    top: 53px;
    right: 29px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 64px;
    padding-bottom: 78px;
    padding-left: 40px;
    padding-right: 40px;

    h3 {
      margin-bottom: 43px;
      font-weight: 600;
      font-size: 48px;
      line-height: 72px;
      text-align: center;
      color: var(--black);
    }

    .buttons {
      display: flex;
      column-gap: 40px;

      button {
        padding: 25px 50px;
        font-weight: 500;
        font-size: 24px;
        line-height: 100%;
        color: #ffffff;
      }
    }
  }
}
