.ReportTabs {
  position: relative;
  display: flex;
  gap: 44px;
  margin-bottom: 50px;

  .line {
    position: absolute;
    bottom: -15px;
    width: 50px;
    height: 5px;
    background-color: var(--primary);
    transition: all 0.1s ease;
  }
}
