@import '../../../assets/styles/variables';

.ReportHeader {
  padding-top: 46px;
  padding-left: 68px;
  padding-right: 68px;
  border-radius: 64px;

  .closeButton {
    position: absolute;
    top: 58px;
    right: 68px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    .title {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 46px;
      line-height: 72px;
      color: $primary;
    }
  }

  .reportInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 77px;

    .infoContainer {
      display: flex;
      align-items: flex-start;
      gap: 41px;

      .info {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        color: var(--text);

        &:first-child {
          padding-right: 41px;
          border-right: 1.5px solid #d9d9d9;
        }

        .title {
          color: var(--black);
        }
      }
    }

    p {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 24px;
      line-height: 150%;
      color: $text;
    }

    .completionRate {
      margin-bottom: 0;
    }
  }

  .buttonContainer {
    .exportIcon {
      margin-left: 12px;
    }
  }
}

@media print {
  .ReportHeader {
    .reportInfo {
      .infoContainer {
        .info {
          padding-left: 20px;
          &:first-child {
            padding-right: 20px;
            padding-left: 0;
            border-right: none;
          }
        }
      }
    }
  }
}
