@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.Breadcrumbs {
  display: flex;
  align-items: center;
  column-gap: 40px;

  &.hasOptions {
    .link {
      &:last-child {
        color: var(--text);
      }
    }
  }

  .link {
    position: relative;
    color: var(--black);
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    text-decoration: none;

    @include onMobile {
      color: #666666;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;

      &.linkWithPrev::before {
        content: '< ';
      }
    }

    &:not(:last-child)::after {
      content: '>';
      color: var(--text);
      position: absolute;
      top: 50%;
      right: -26px;
      transform: translateY(-50%);
    }
  }

  .backButton {
    padding: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    background: transparent;
  }
}
