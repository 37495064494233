.FolderReportHeader {
  display: inline-flex;
  justify-content: flex-start;
  gap: 41px;
  margin-bottom: 71px;
  padding-top: 34px;
  padding-left: 35px;
  padding-right: 85px;
  padding-bottom: 39px;
  border: 1px solid #dddddd;
  border-radius: 5px;

  .info {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    color: var(--text);

    &:first-child {
      padding-right: 41px;
      border-right: 1.5px solid #d9d9d9;
    }

    .title {
      color: var(--black);
    }
  }
}

@media print {
  .FolderReportHeader {
    display: flex;
    justify-content: space-between;
    .info {
      flex: 1;
      padding-left: 20px;
      &:first-child {
        padding-right: 20px;
        padding-left: 0;
        border-right: none;
      }
    }
  }
}
