.Tab {
  padding: 0;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  color: var(--text);
  background-color: transparent;
  border: none;

  &.active {
    color: var(--primary);
  }
}
