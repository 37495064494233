@import '../../../../assets//styles/variables';

.Stat {
  min-height: 263px;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 29px;
  padding-bottom: 31px;
  background-color: #f8f8f8;
  border-radius: 16px;

  p,
  h4 {
    margin: 0;
  }

  .title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
  }

  .value {
    margin-bottom: 10px;
    font-size: 48px;
    line-height: 72px;
    font-weight: 600;
    color: var(--primary);
  }

  .description,
  li {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: var(--text);
  }

  ul {
    margin: 0;
    margin-top: 9px;
    padding: 0;
  }
}
