@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.SearchBar {
  position: relative;
  height: 65px;

  @include onMobile {
    width: 100%;
    height: 46px;
  }

  &.active input {
    width: 531px;

    &::placeholder {
      opacity: 1;
      color: var(--black);

      @include onMobile {
        color: white;
      }
    }

    @include onMobile {
      width: 100%;

      &:focus::placeholder {
        visibility: hidden;
      }
    }
  }

  input {
    height: 65px;
    width: 46px;
    padding-left: 76px;
    padding-right: 29px;
    border: 1px solid black;
    border-radius: 45px;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 33px;
    color: var(--black);
    outline: none;
    transition: width 0.3s;

    @include onMobile {
      width: 100%;
      height: 46px;
      padding-left: 20px;
      padding-right: 60px;
      font-family: Poppins;
      font-size: 18px;
      line-height: 27px;
      color: white;
      background-color: transparent;
      border: none;
      border-radius: 0;
    }

    &::placeholder {
      opacity: 0;
      color: white;
      transition: opacity 0.3s;
    }
  }

  .icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 29px;
    transform: translateY(-50%);
    transition: transform 0.3s;

    @include onMobile {
      transform: translateY(-50%);
      left: unset;
      right: 20px;
    }
  }
}
