@import '../../../assets/styles/variables';

.PlaceholderSection {
  position: relative;

  .sectionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 235px;
    height: 148px;
  }

  .title {
    visibility: hidden;
    position: absolute;
    left: 0;
    bottom: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 24px;
    padding-left: 18px;
    padding-right: 18px;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    text-align: center;
    color: black;
    border-radius: 16px;
    user-select: none;
  }
}
