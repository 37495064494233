@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.SignInPage {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    width: 100%;
    max-width: 702px;
    height: 100%;

    @include onMobile {
      height: 100%;
    }
  }

  .logoContainer {
    display: none;

    @include onMobile {
      //  aspect-ratio: 341 / 191;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
    }

    .logo {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .signUp {
    margin-bottom: 64px;
    align-self: flex-end;

    @include onMobile {
      display: none;
    }

    a {
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      text-align: right;
      text-decoration: none;
      color: $pink;
    }
  }

  h1 {
    margin-bottom: 72px;
    align-self: flex-start;
    font-weight: 700;
    font-size: 72px;
    line-height: 108px;
    color: $primary;

    @include onMobile {
      margin: 0 0 40px;
      font-size: 48px;
    }
  }

  form {
    width: 100%;
    max-width: 702px;

    align-self: flex-start;

    .inputs {
      display: flex;
      flex-direction: column;
      row-gap: 84px;
      margin-bottom: 80px;

      @include onMobile {
        margin-bottom: 27px;
      }
    }

    .row {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include onMobile {
        gap: 16px;
        height: auto;
      }

      .forgotPassword {
        margin: -21px 0 0;
        align-self: flex-start;
        font-size: 20px;
        line-height: 30px;
        color: #888888;

        @include onMobile {
          margin: 0;
          text-align: center;
          align-self: center;
        }

        a {
          color: $pink;
          text-decoration: none;

          @include onMobile {
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
