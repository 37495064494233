.dialog {
  max-width: 1153px;
}

.backdrop {
  min-width: calc(100vw / var(--zoom));
  min-height: calc(100vh / var(--zoom));
}

.ImportFileModal {
  position: relative;
  border-radius: 15px;

  .closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 73px;
    padding-bottom: 85px;

    .title {
      margin-bottom: 47px;
      font-family: Poppins;
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;
      color: var(--black);
    }

    .buttonsContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 24px;
    }
  }
}
