@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.DesktopHeader {
  width: 100%;
  height: 115px;
  padding-top: 25px;
  padding-right: 46px;
  padding-bottom: 25px;
  display: flex;
  justify-content: flex-end;

  @include onMobile {
    height: 112px;
    padding: 14px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    background: $red;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .searchBarContainer {
    margin-right: 55px;

    @include onMobile {
      margin-right: unset;
    }
  }

  .logo {
    height: 100%;

    @include onMobile {
      display: none;
    }
  }
}
