@import '../../../assets/styles/variables';

.UsersTable {
  width: 100%;

  thead {
    position: sticky;
    top: 0;
    height: 50px;
    background-color: white;

    tr {
      // border-bottom: 1px solid #d9d9d9;
      box-shadow: inset 0 -1px 0 #d9d9d9;

      th {
        padding: 0;
        padding-bottom: 25px;
        font-size: 24px;
        line-height: 36px;

        /* &:first-child {
          padding-left: 36px;
        }

        &:last-child {
          padding-right: 36px;
        } */
      }
    }
  }

  tbody {
    tr {
      height: 100px;
      td {
        //  padding-right: 10px;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: var(--text);
        text-align: left;
        border-bottom: 1px solid #d9d9d9;

        /*   &:first-child {
          padding-left: 36px;
        }

        &:last-child {
          padding-right: 36px;
        } */
      }
    }
  }
}
