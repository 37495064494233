.ViewStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 43px;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: white;
  background-color: #666666;
  border-radius: 7px;

  &.viewed {
    background-color: var(--primary);
  }
}
