@import '../../assets/styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
  padding-left: 42px;
  padding-right: 46px;

  .exportIcon {
    margin-left: 12px;
  }
}

.ViewerReportPage {
  min-height: calc(100vh / var(--zoom) - var(--header-height) - 89px);
  padding-left: 42px;
  padding-right: 46px;
  background-color: white;

  .tableContainer {
    h4 {
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 20px;
      margin-bottom: 20px;
    }
  }

  .container {
    padding: 20px 40px;

    header {
      display: flex;
      justify-content: space-between;

      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 133%;
        color: $text;
      }

      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 133%;
        color: $text;
      }
    }

    .reportInfo {
      display: flex;
      justify-content: space-between;

      .meetingTitle {
        margin-bottom: 28px;
      }

      p {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
        color: $text;
      }

      .completionRate {
        margin-bottom: 0;
      }

      .buttonContainer {
        display: flex;
        align-items: flex-end;
      }
    }
  }
}

@media print {
  .ViewerReportPage {
    padding-top: 40px;
  }
}
