@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.Progress {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 200px;

  @include onMobile {
    width: unset;
  }

  .loaderContainer {
    padding-top: 5px;
    transform: scale(0.8);
  }

  .bar {
    height: 100%;
    background: $red;
  }

  .step {
    text-transform: capitalize;
    font-size: 15px;

    @include onMobile {
      line-height: 18px;
    }
  }
}
