.rdw-dropdown-selectedtext {
  display: unset;
  padding: 0;

  .rdw-dropdown-carettoopen,
  .rdw-dropdown-carettoclose {
    display: none;
  }
}

.rdw-option-active {
  box-shadow: none;
}

.rdw-option-wrapper {
  border: 2px solid #000000;
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    box-shadow: none;
  }
}

.rdw-dropdown-optionwrapper {
  overflow: visible;
}

.rdw-editor-toolbar {
  display: flex;
  gap: 24px;
  align-items: center;
  height: 57px;
  margin-bottom: 0;
  padding: 0;
  position: absolute;
  bottom: 51px;
  border: none;

  .rdw-inline-wrapper {
    position: relative;
    top: 5px;
    height: 100%;
    gap: 23px;
  }

  .rdw-history-wrapper {
    display: flex;
    gap: 16px;
    padding-top: 5px;
    height: 55px;

    > div {
      width: 55px;
      max-height: 55px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.rdw-link-wrapper {
  width: 55px;
  height: 55px;
  margin: 0;
  padding: 1px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  > div {
    //   width: 100%;
    //   max-height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.rdw-link-modal {
  height: unset;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rdw-link-modal-target-option {
  display: none;
}

.rdw-dropdown-wrapper:has(a[title='Text Align']) {
  position: relative;
  top: -2px;
  left: -2px;
  width: 26px;
  height: 21px;
  border-radius: 0;

  img {
    border-radius: 0;
  }
}

.rdw-dropdown-wrapper {
  height: 55px;
  width: 55px;
  border-radius: 8px;
  border: none;

  &:hover {
    box-shadow: none;
  }

  img {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
}

.public-DraftEditor-content {
  border: 1px solid transparent;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  bottom: 0;
}

.public-DraftStyleDefault-ltr {
  margin: 0;
}

.DraftEditor-root {
  height: 100%;
}

.rdw-editor-main {
  padding: 20px;
}

.rdw-center-aligned-block > div {
  text-align: center;
  word-break: break-all;
}

.rdw-left-aligned-block > div {
  word-break: break-all;
}

.rdw-fontsize-wrapper {
  position: relative;
  top: 10px;
  width: 100px;
}

.rdw-dropdown-selectedtext[title='Font Size'] {
  display: flex;
  width: 100px;
  height: 47px;
  padding-left: 17px;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  color: black;
  text-decoration: none;
  background-color: #f8f8f8;
  border-radius: 5px;

  &::after {
    content: '';
    display: block;
    width: 14px;
    height: 8px;
    margin-left: 9px;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.90944 4.98645L11.8594 0.0364532L13.2734 1.45045L6.90944 7.81445L0.545437 1.45045L1.95944 0.0364532L6.90944 4.98645Z' fill='%23666666'/%3E%3C/svg%3E%0A");
  }
}
