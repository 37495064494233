@import '../../assets/styles/variables';

.backdrop {
  min-width: calc(100vw / var(--zoom));
  min-height: calc(100vh / var(--zoom));
}

.dialog {
  max-width: 940px;
}

.PublishMeetingModal {
  position: relative;
  padding-top: 44px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 61px;
  border-radius: 10px;

  .closeIcon {
    position: absolute;
    top: 60px;
    right: 60px;
    cursor: pointer;
  }

  .form {
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 47px;
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;
      text-align: left;
      color: var(--black);
    }

    .commentContainer {
      margin-bottom: 31px;
      position: relative;

      textarea {
        height: 172px;
        font-size: 22px;
      }
    }
  }
}
