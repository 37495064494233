.InfoButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  background-color: transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1px solid gray;
}
