@import '../../assets/styles/variables';

.backdrop {
  background-color: var(--black) !important;
  opacity: 0.7 !important;
}

.dialog {
  max-width: 937px;
}

.ShareModal {
  position: relative;
  padding-top: 44px;
  padding-left: 58px;
  padding-right: 58px;
  padding-bottom: 46px;
  border-radius: 10px;
  background-color: white;

  .closeIcon {
    position: absolute;
    top: 36px;
    right: 36px;
    cursor: pointer;
  }

  header {
    margin-bottom: 22px;

    h1 {
      margin: 0;
      font-weight: 600;
      font-size: 48px;
      line-height: 72ox;
      color: var(--black);
    }
  }

  .form {
    margin-bottom: 42px;

    .row {
      display: flex;

      &.inviteRow {
        flex-direction: column;
        margin-bottom: 45px;
        gap: 38px;
      }

      .col {
        flex: 1;

        .title {
          h2 {
            margin-bottom: 10px;
            display: flex;
            gap: 10px;
            font-size: 24px;
            line-height: 36px;
            color: var(--black);

            .infoIconContainer {
              font-weight: normal;
              font-size: unset;
            }
          }

          hr {
            margin: 0;
            margin-bottom: 12px;
          }
        }
      }

      .inviteUsersContainer {
        flex-grow: 2;
      }

      .roleOptionsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .error {
        padding-left: 5px;
        color: $red;
      }
    }

    .messageContainer {
      position: relative;
    }
  }

  footer {
    display: flex;
    flex-direction: column-reverse;
    gap: 21px;

    .message {
      margin: 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      color: #888;
    }
  }
}
