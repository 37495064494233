.PublishingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d9d9d9;
    filter: brightness(75%);
    border-radius: 10px;
  }

  .text {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .title {
      margin-bottom: 17px;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: white;
    }

    .info {
      max-width: 627px;
      margin: 0;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: center;
      color: white;
    }
  }
}
