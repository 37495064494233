@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.Comments {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 80px;

  .switchContainer {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;

    @include onMobile {
      padding-bottom: 28px;
      padding-left: 18px;
      padding-right: 12px;
      border-bottom: 1px solid #dddddd;
    }
  }

  .container {
    width: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 57px;

      @include onMobile {
        padding-top: 21px;
        flex-direction: column-reverse;
        margin-bottom: 38px;
      }

      .tabs {
        display: flex;
        gap: 44px;

        @include onMobile {
          justify-content: center;
          gap: 22px;
          padding-top: 29px;
        }

        .tab {
          font-size: 26px;
          font-weight: 600;
          line-height: 39px;
          text-align: left;
          color: var(--text);
          white-space: nowrap;
          cursor: pointer;

          @include onMobile {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
          }

          &.active {
            position: relative;
            color: var(--primary);

            &::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: -14px;
              width: 100%;
              height: 4px;
              background-color: var(--primary);

              @include onMobile {
                height: 3px;
                bottom: -10px;
              }
            }
          }
        }
      }
    }

    .questionsList {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 100%;
      padding-bottom: 40px;

      @include onMobile {
        padding-left: 18px;
        padding-right: 20px;
      }
    }

    .commentsList {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;

      .scrollButtonContainer {
        position: absolute;
        z-index: 4;
        top: 60px;
        right: -84px;
        display: none;
        flex-direction: column;

        span {
          color: $red;
          font-weight: bold;
        }

        button {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          width: 80px;
          height: 94px;
          margin-bottom: 19px;
          padding-bottom: 10px;
          background-color: $red;
          border-radius: 50%;
          border: 3px solid $primary;

          svg {
            width: 75%;
          }
        }
      }
    }

    .noQuestions {
      margin: 0;
      margin-top: 40px;
      font-size: 24px;
      font-weight: bold;

      @include onMobile {
        padding-left: 18px;
        padding-right: 20px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}
