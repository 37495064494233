@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.CreateOrUpdateMeetingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: calc(100vh / var(--zoom) - var(--header-height));

  .overlay {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 86px;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    .message {
      position: relative;
      top: -86px;
      padding: 30px;
      font-weight: bold;
      background-color: white;
      border-radius: 10px;
    }
  }

  .navigationContainer {
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    // flex: 1;
    width: 100%;
    padding-left: 42px;
    padding-right: 46px;
    // overflow: hidden;

    @include onMobile {
      background: #ffffff;
    }

    .innerContainer {
      display: flex;
      flex-direction: column;

      @include onMobile {
        display: none;
      }

      .header {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        margin-bottom: 27px;
        padding-top: 25px;
      }

      .mediaContainerContainer {
        position: relative;

        .autoSaveToggleContainer {
          position: absolute;
          top: -35px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 100%;
          padding-right: 120px;
        }
      }

      .meetingForm {
        display: flex;
        flex-direction: column;

        .meetingInfo {
          margin-bottom: 12px;
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          position: relative;
          left: -40px;

          h2 {
            width: 100%;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            line-height: 75%;
            color: $text;
          }

          .meetingTitle {
            position: relative;
            display: flex;
            gap: 25px;

            .undoButton {
              width: 48px;
              height: 48px;
              background-color: #111111;
              border-radius: 12px;
              border: none;

              &:disabled {
                background-color: #ccc;
                cursor: not-allowed;
              }

              img {
                width: 80%;
                height: 80%;
              }
            }

            .titleContainer {
              max-width: 319px;
              padding: 0 16px;
              display: flex;
              flex-grow: 1;
              gap: 10px;
              position: relative;
              background-color: #ffffff;
              border-radius: 10px;
              overflow: hidden;
            }

            h2 {
              display: inline;
              text-align: center;
              line-height: 48px;
              font-size: 28px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .updateTitle {
              padding: 0;
              position: absolute;
              top: -12px;
              right: -6px;
              border: none;
              background-color: transparent;

              img {
                height: 24px;
                width: 24px;
              }
            }
          }

          .meetingVersion {
            display: flex;
            column-gap: 8px;
            padding-left: 73px;

            h2 {
              width: unset;
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
