@import '../../../../assets/styles/mixins';

.ControlsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;

  @include onMobile {
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: white;

    @include onMobile {
      display: none;
    }
  }

  .time {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: white;

    @include onMobile {
      font-size: 12px;
      line-height: 12px;
    }
  }
}
