@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.UploadMenu {
  display: none;

  @include onMobile {
    padding: 0;
    padding-top: 41px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background: transparent;
    border: none;

    .outerButtonContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 102px;
      width: 102px;
      height: 102px;
      background-color: rgba(248, 88, 92, 0.04);
      border-radius: 50%;

      .innerButtonContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 78px;
        height: 78px;
        background-color: rgba(248, 88, 92, 0.1);
        border-radius: 50%;
      }
    }

    span {
      font-size: 18px;
      line-height: 27px;
      color: $text;
    }
  }
}
