.EmptyFolderMessage {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  height: 100%;
  padding-top: 128px;

  .emptyFolderIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 126px;
    height: 126px;
    background-color: #f8f8f8;
    border-radius: 50%;
  }

  .message {
    font-size: 18px;
    line-height: 27px;
    color: var(--black);
  }
}
