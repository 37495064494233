@import '../../../assets/styles/mixins';

.Controls {
  position: relative;
  z-index: 1;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 12px;
  background: linear-gradient(
    180deg,
    rgba(8, 9, 13, 0) 0%,
    rgba(8, 9, 13, 0.9) 80.37%
  );

  @include onMobile {
    padding-left: 18px;
    padding-right: 21px;
    padding-bottom: 20px;
  }
}
