@import '../../../../assets/styles/variables';

.Progress {
  position: relative;
  margin-bottom: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 2;
  transition: transform 0.2s;
  cursor: pointer;

  &.dragging,
  &:hover {
    .progressBar {
      transform: scaleY(2);

      .progress::after {
        opacity: 1;
        transform: scaleY(0.5);
      }
    }
  }

  .progressBar {
    width: 100%;
    height: 2px;
    background-color: rgba(217, 217, 217, 0.4);
    transition: transform 0.2s;
    border-radius: 20px;

    .progress {
      position: absolute;
      width: 0;
      height: 2px;
      background-color: white;
      border-radius: 20px;

      &::after {
        content: '';
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: white;
        right: -10px;
        top: -9px;
        transform: scale(0.2);
        opacity: 0;
        transition: transform 0.2s, opacity 0.2s;
      }
    }
    /* 
    &.dragging,
    &:hover {
      transform: scaleY(2);
    }

    &.dragging .progress::after,
    &:hover .progress::after {
      opacity: 1;
      transform: scaleY(0.5);
    } */
  }
}
