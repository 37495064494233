@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.UserBlock {
  display: flex;
  align-items: center;
  cursor: pointer;

  @include onMobile {
    margin-left: auto;
  }

  svg {
    margin-left: 13px;

    @include onMobile {
      display: none;
    }
  }

  .username {
    margin-right: 18px;
    font-weight: 500;
    font-size: 20px;
    line-height: 89%;
    color: black;

    @include onMobile {
      display: none;
    }
  }

  .userMenuContainer {
    position: relative;
    z-index: 3;

    .userMenu {
      width: 296px;
      margin: 0;
      padding: 32px;
      display: none;
      flex-direction: column;
      row-gap: 16px;
      position: absolute;
      top: 58px;
      right: 2px;
      border-radius: 16px;
      list-style: none;
      background-color: #ffffff;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

      &.isVisible {
        display: flex;
      }

      @include onMobile {
        padding: 16px;

        .profileButton {
          display: none;
        }
      }

      li {
        button {
          height: 48px;
          padding: 0;
          display: flex;
          flex: 1 1 100%;
          column-gap: 16px;
          justify-content: space-between;
          align-items: center;
          font-size: 24px;
          line-height: 100%;
          border: none;
          background-color: transparent;
          color: $text;
        }
      }
    }
  }

  .userIcon,
  .userPhoto {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;

    @include onMobile {
      height: 46px;
      width: 46px;
      background: #ffffff;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.backdrop {
  display: none;

  @include onMobile {
    display: block;
    position: fixed;

    &.isVisible {
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 2;
    }
  }
}
