@import '../../../assets/styles/_variables';

.SectionGap {
  min-width: 40px;
  height: 100%;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    cursor: auto;
  }

  .addButtonContainer {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;

    .addButton {
      width: 80px;
      height: 80px;
      padding: 0;
      background-color: $red;
      border-radius: 50%;
      border: none;
    }
  }
}

.placeholderContainer {
  margin-left: 40px;
  margin-right: 40px;
}
