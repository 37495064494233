@import '../../../assets/styles/variables';

.CreateFolderMenu {
  max-width: 524px;
  min-width: 285px;
  padding-top: 24px;
  padding-left: 32px;
  padding-right: 28px;
  padding-bottom: 34px;
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  z-index: 1;
  box-shadow: 0px 4px 24px 0px #0000001c;
  border-radius: 10px;
  background-color: #ffffff;

  .optionsList {
    ul {
      display: flex;
      flex-direction: column;
      gap: 29px;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        button {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 500;
          font-size: 22px;
          line-height: 33px;
          color: var(--text);
          border: none;
          background-color: transparent;

          img {
            margin-right: 8px;
          }

          &:disabled {
            cursor: not-allowed;
            opacity: 0.4;
          }
        }
      }
    }
  }

  .departmentFolder {
    h5 {
      margin-bottom: 16px;
      color: var(--black);
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
    }

    .or {
      margin-top: 11px;
      margin-bottom: 7px;
    }

    label {
      display: flex;
      flex-direction: column;
      gap: 4px;

      span {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 27px;
      }

      .assignFolder {
        display: flex;
        gap: 8px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .assignFolderContainer {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }
  }

  .subfolder {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .nameContainer {
      margin-bottom: 8px;
    }
  }

  .buttonContainer {
    margin-top: 12px;
  }

  input {
    padding: 8px 12px;
    border: 1px solid #d4d4d4;
    border-radius: 60px;
    line-height: 100%;
    color: $text;
    outline: none;
  }
}
