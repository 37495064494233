@import '../../../assets/styles/variables';

.VideosReport {
  padding-bottom: 40px;
  table {
    width: 100%;
    // table-layout: fixed;
    thead {
      height: 50px;

      tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        th {
          padding: 0;
          padding-right: 10px;
          padding-bottom: 26px;
          font-size: 24px;
          line-height: 36px;
          font-weight: 500;
          color: var(--black);

          /*  &:first-child {
            padding-left: 36px;
          }

          &:last-child {
            padding-right: 36px;
          } */
        }
      }
    }

    tbody {
      tr {
        height: 120px;
        td {
          padding-right: 10px;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          color: var(--text);
          text-align: left;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);

          /*   &:first-child {
            text-align: left;
            padding-left: 36px;
          }

          &:last-child {
            padding-right: 36px;
          } */
        }
      }
    }
  }
}
