@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.sections {
  position: relative;
  width: 100%;
  margin-bottom: 44px;

  .switch {
    position: absolute;
    z-index: 1;
    top: 70px;
    left: -30px;
    width: 48px;
    height: 48px;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;

    &.switchRight {
      left: unset;
      top: 100px;
      right: -30px;
      transform: scale(-1, -1);
    }
  }

  .sectionsListContainer {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;

    .sectionsList {
      position: relative;
      left: 20px;
      width: 100%;
      height: 239px;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      list-style: none;
      overflow: hidden;

      .inner {
        position: absolute;
        top: 33px;
        display: flex;
        gap: 40px;
        transition: left 0.2s;

        li {
          padding: 0;
          margin: 0;

          &.sectionItem {
            position: relative;
            width: 235px;
            min-width: 235px;
            height: 148px;
            border-radius: 20px;
            cursor: pointer;

            .title {
              position: absolute;
              left: 0;
              bottom: -50px;
              display: flex;
              align-items: center;
              width: 100%;
              height: 24px;
              padding-left: 18px;
              padding-right: 18px;
              border-radius: 16px;

              span {
                width: 100%;
                font-family: Poppins;
                font-size: 22px;
                font-weight: 500;
                line-height: 33px;
                text-align: center;
                color: black;
                background-color: transparent;
                border: none;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .sectionContent {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 17px;
            }

            .noteSectionContent {
              background-color: #efede9;
            }

            .audioImageBackground,
            video,
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 17px;
            }

            .audioImageBackground {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          &.current {
            border: 3px solid $red;

            .title {
              bottom: -53px;
            }
          }
        }
      }
    }
  }
}
