.DeleteSectionIcon {
  position: absolute;
  z-index: 1;
  top: -16px;
  right: -16px;
  font-size: 0;
  cursor: pointer;

  svg {
    path {
      transform: translate(12px, 10px);
    }
  }
}
