.SectionMenu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  /*  &.emptyList {
    margin-left: 120px;
  } */

  .toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    cursor: pointer;

    span {
      font-family: Poppins;
      font-size: 26px;
      font-weight: 600;
      line-height: 39px;
    }
  }

  .fileInput {
    display: none;
  }

  .menu {
    position: fixed;
    z-index: 11;
    left: 20px;
    bottom: 25px;
    padding-top: 21px;
    padding-left: 26px;
    padding-right: 29px;
    padding-bottom: 34px;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 4px 24px 0px #0000001c;
    transform: translateX(calc(-100% - 15px));

    ul {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 0;
      padding: 0;
      list-style: none;

      .subMenu {
        position: absolute;
        left: 0;
        top: 0;
        transform: translateX(calc(-100%));
        padding-right: 40px;

        &.right {
          left: auto;
          right: 0;
          transform: translateX(calc(100%));
          padding-left: 40px;
        }

        ul {
          margin: 0;
          padding-top: 21px;
          padding-left: 26px;
          padding-right: 21px;
          padding-bottom: 27px;
          background-color: white;
          border-radius: 10px;
          box-shadow: 0px 4px 24px 0px #0000001c;
        }
      }

      li {
        height: 48px;
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        margin: 0;
        padding: 0;
        font-size: 22px;
        line-height: 33px;
        color: var(--text);
        white-space: nowrap;
        user-select: none;
        cursor: pointer;
        transition: color 0.2s;

        &:hover {
          color: var(--black);
        }

        &.disabled {
          opacity: 0.2;
          cursor: auto;
        }

        .nameContainer {
          display: flex;
          align-items: center;
          flex: 1;
          gap: 10px;
          justify-content: space-between;
        }
      }
    }
  }
}
