@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.dialog {
  max-width: 1332px;

  @include onMobile {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  &.multipleChoice {
    max-width: 937px;
  }

  &.submitted {
    max-width: 548px;

    @include onMobile {
      max-width: 100%;
    }
  }
}

.backdrop {
  opacity: 1 !important;
  min-width: calc(100vw / var(--zoom));
  min-height: calc(100vh / var(--zoom));
}

.QuestionModal {
  position: relative;
  padding-top: 67px;
  padding-left: 77px;
  padding-right: 77px;
  padding-bottom: 66px;
  border-radius: 10px;

  @include onMobile {
    min-height: 100dvh !important;
    padding-top: 81px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
    border-radius: 0;
  }

  &.submitted {
    border-radius: 20px;

    @include onMobile {
      border-radius: 0;
    }
  }

  &.multipleChoice {
    padding-top: 50px;
    padding-left: 51px;
    padding-right: 51px;
    padding-bottom: 60px;

    .question {
      max-width: 758px;
    }

    .buttons {
      justify-content: space-between;
    }
  }

  .closeIcon {
    position: absolute;
    top: 86px;
    right: 76px;
    display: block;
    width: 27px;
    height: 27px;
    font-size: 0;
    background-image: url(../../assets/images/closeIcon.svg);
    background-size: contain;
    cursor: pointer;

    @include onMobile {
      top: 27px;
      right: 20px;
      width: 17px;
      height: 17px;
      background-image: url(../../assets/images/closeIconBlack.svg);
    }
  }

  .submitMessage {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    @include onMobile {
      padding-left: 20px;
      padding-right: 20px;
    }

    p {
      width: 380px;
      text-align: center;
      font-size: 20px;
      margin: 0;

      @include onMobile {
        width: unset;
      }

      &.title {
        font-weight: bold;
      }
    }
  }

  .question {
    margin-bottom: 55px;
    font-size: 48px;
    font-weight: 600;
    line-height: 65px;
    text-align: left;
    color: var(--black);

    @include onMobile {
      margin-bottom: 31px;
      font-size: 28px;
      line-height: 42px;
    }
  }

  .requiredInfo {
    margin: 20px 0;
    padding-left: 50px;
    color: #898989;
  }

  .form {
    display: flex;
    justify-content: space-between;
    gap: 31px;
    margin-bottom: 51px;
    position: relative;

    @include onMobile {
      flex-direction: column;
      gap: 17px;
      margin-bottom: 31px;
    }

    .userpic {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 129px;
      height: 104px;
      border-radius: 50%;

      @include onMobile {
        height: unset;
      }

      img {
        width: 75px;
        height: 75px;
        min-height: 75px;
        margin-bottom: 14px;
        object-fit: cover;
        border-radius: 50%;

        @include onMobile {
          margin-bottom: 8px;
        }
      }

      .username {
        width: 100%;
        margin: 0;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        text-align: center;
        color: var(--black);

        @include onMobile {
          font-size: 18px;
          line-height: 27px;
        }
      }
    }

    textarea {
      width: 100%;
      height: 254px;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 28px;
      padding-right: 42px;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      background-color: white;
      border-radius: 18px;
      border: 1px solid #666666;
      resize: none;

      @include onMobile {
        height: 173px;
        padding-left: 24px;
        font-size: 18px;
        font-size: 18px;
      }

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    .emojiIcon {
      position: absolute;
      top: 16px;
      right: 23px;
      cursor: pointer;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0;
    margin-bottom: 51px;
    padding: 0;
    list-style: none;

    @include onMobile {
      margin-bottom: 31px;
    }

    li {
      label {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        color: $text;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 22px;

    @include onMobile {
      flex-direction: column;
      gap: 13px;

      button {
        width: 100% !important;
      }
    }

    .messageContainer {
      p {
        margin: 0;
        font-family: 'Inter';
        font-style: normal;
        font-size: 20px;
        line-height: 29px;
        color: $text;

        &:first-of-type {
          font-weight: 600;
        }
      }
    }
  }
}
