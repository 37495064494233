@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.replyBoxContainer,
.replies {
  margin-left: 70px;

  @include onMobile {
    margin-left: 21px;
  }
}

.Comment {
  position: relative;
  display: flex;
  margin-bottom: 82px;

  @include onMobile {
    margin-bottom: 68px;
  }

  .commentActions {
    position: absolute;
    left: 40px;
    right: 0;
    bottom: -43px;
    display: flex;
    align-items: center;
    gap: 8px;

    @include onMobile {
      left: 65px;
      bottom: -40px;
    }

    .saveButton {
      position: absolute;
      top: calc(50% - 24px + 8px);
      right: 0;
      display: flex;
      justify-content: center;
      width: 191px;
      height: 48px;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      color: white;
      background-color: $pink;
      border-radius: 16px;
      border: none;
    }

    .replyCount {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: var(--text);

      @include onMobile {
        position: relative;
        top: 1px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .likesContainer {
      display: flex;
      align-items: center;
      gap: 7px;

      @include onMobile {
        gap: 5px;
      }

      &.liked {
        .likeText {
          font-weight: 600;
        }
      }

      .likeIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
      }
    }

    button {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 0;
      background-color: transparent;
      border: none;
    }

    button,
    .divider {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $text;
    }
  }

  .userpic {
    position: absolute;
    left: 11px;
    //   transform: translateX(-100%);
    width: 63px;
    min-width: 63px;
    height: 63px;
    margin-right: 16px;
    border-radius: 50%;

    @include onMobile {
      left: 18px;
      width: 39px;
      min-width: 39px;
      height: 39px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .content {
    width: 100%;
    min-height: 130px;
    padding-left: 89px;
    padding-right: 32px;
    font-size: 20px;
    background-color: white;

    @include onMobile {
      min-height: unset;
      padding-left: 65px;
      padding-right: 20px;
      font-size: 16px;
      line-height: 24px;
    }

    header {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-bottom: 28px;

      @include onMobile {
        margin-bottom: 8px;
      }

      .userInfo {
        margin-bottom: 6px;

        h1 {
          margin-bottom: 0;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          color: var(--black);

          @include onMobile {
            font-size: 16px;
            line-height: 24px;
          }
        }

        h2 {
          margin: 0;
          font-family: 'Outfit';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $text;
        }
      }

      .headerRight {
        display: flex;
        column-gap: 7px;
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        color: $text;
      }

      .timestamps {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        color: var(--text);

        @include onMobile {
          font-size: 16px;
          line-height: 24px;
        }

        .meetingTime {
          cursor: pointer;
        }
      }

      .menuToggle {
        user-select: none;
        cursor: pointer;
      }

      .commentMenu {
        position: absolute;
        z-index: 1;
        width: 300px;
        height: 176px;
        backdrop-filter: blur(2px);
        background-color: white;
        border-radius: 16px;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05));
        transform: translateY(26px);

        ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 16px;
          height: 100%;
          margin: 0;
          padding: 0;
          background-color: transparent;
          list-style: none;

          li {
            gap: 16px;
            padding: 0;
            padding-left: 32px;
            margin: 0;

            button {
              display: flex;
              align-items: center;
              gap: 16px;
              width: 48px;
              height: 48px;
              font-family: 'Outfit';
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 24px;
              color: $text;
              padding: 0;
              background-color: transparent;
              border: none;
              cursor: pointer;

              .iconContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 48px;
                height: 48px;
                background-color: white;
                border-radius: 50%;
                border: none;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
              }
            }
          }
        }
      }
    }

    .textContent {
      position: relative;
      word-break: break-word;

      &.editable {
        div {
          &:focus {
            outline: 0px solid transparent;
          }
        }
      }
    }
  }
}
