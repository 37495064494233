@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.ProfilePage {
  min-height: calc(100vh / var(--zoom) - var(--header-height));

  @include onMobile {
    min-height: unset;
  }

  .container {
    display: flex;
    height: calc(100vh / var(--zoom) - var(--header-height));

    @include onMobile {
      height: unset;
    }

    .main {
      width: 100%;
      padding-top: 16px;
      padding-left: 46px;
      padding-right: 46px;

      @include onMobile {
        padding-left: 20px;
        padding-right: 20px;
      }

      .fakeBreadCrumb {
        margin-bottom: 22px;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        color: var(--black);
      }

      .account {
        display: flex;
        column-gap: 102px;

        @include onMobile {
          flex-direction: column;
          gap: 32px;
        }

        .passwordHint {
          position: absolute;
          margin-top: 10px;
          padding-left: 32px;
          color: var(--pink);

          @include onMobile {
            display: inline-block;
            position: static;
          }
        }

        .userPhoto {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 36px;
          padding-top: 27px;

          @include onMobile {
            row-gap: 20px;
            padding-top: 10px;
          }

          img {
            width: 106px;
            height: 106px;
            border-radius: 50%;
            background-color: transparent;
            object-fit: cover;
          }

          ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 16px;
            list-style: none;

            li {
              .removeButton {
                padding: 0;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                border: none;
                background-color: transparent;
                color: var(--text);

                &:disabled {
                  opacity: 0.6;
                  cursor: not-allowed;
                }
              }
            }
          }
        }

        h1 {
          margin-bottom: 18px;
          font-size: 48px;
          font-weight: 600;
          line-height: 72px;
          color: var(--black-color);

          @include onMobile {
            margin-bottom: 0;
            font-size: 24px;
            line-height: 36px;
          }
        }

        form {
          max-width: 786px;
          display: flex;
          flex-direction: column;
          row-gap: 31px;
          padding-bottom: 114px;

          @include onMobile {
            padding-bottom: 60px;
          }

          .row {
            display: flex;
            align-items: center;
            column-gap: 19px;

            @include onMobile {
              flex-direction: column;
              gap: 31px;
            }
          }

          .confirmButtonContainer {
            align-self: flex-end;
            margin-top: 47px;

            @include onMobile {
              margin-top: 0;
            }
          }
        }
      }

      .notificationPreferences {
        display: flex;
        flex-direction: column;

        h1 {
          margin-bottom: 10px;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 75%;
          color: $text;
        }

        .list {
          display: flex;
          flex-direction: column;
          row-gap: 8px;

          .hint {
            margin-bottom: 16px;
            text-align: right;
            font-weight: 700;
            font-size: 24px;
            line-height: 100%;
            color: $text;
          }

          .item {
            padding: 28px 40px;
            display: flex;
            column-gap: 40px;
            justify-content: space-between;
            align-items: center;
            border-radius: 60px;
            background-color: #ffffff;
            box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);

            p {
              margin: 0;
              font-size: 24px;
              line-height: 100%;
              color: $text;
            }
          }
        }
      }
    }
  }
}
