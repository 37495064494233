.sortableTh {
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
  color: var(--black);
  cursor: pointer;

  &:not(.sortable) {
    cursor: default;
  }

  .sortOrder {
    .chevron {
      width: 15px;
      margin-left: 2px;
    }

    &.asc {
      .chevron {
        transform: rotate(180deg);
      }
    }
  }
}
