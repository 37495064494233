.TableRowAction {
  display: flex;
  gap: 29px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
