@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.Switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  // width: 51px;
  height: 37px;
  padding-right: 51px;
  cursor: pointer;

  @include onMobile {
    height: 25px;
  }

  &.withTooltip {
    .label {
      padding-left: 5px;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: var(--primary);
    }

    &:checked + .slider:before {
      transform: translateX(calc(var(--width) - 27px - 5px));

      @include onMobile {
        transform: translateX(calc(var(--width) - 18px - 5px));
      }
    }
  }

  .onLabel {
    position: absolute;
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    color: white;
    right: calc(var(--width) - 40px - 5px);
    opacity: 1;
    transition: all 0.2s ease;

    &.hidden {
      opacity: 0;
    }

    @include onMobile {
      font-size: 12px;
      line-height: 18px;
      right: calc(var(--width) - 24px - 5px);
    }
  }

  .label {
    padding-right: 14px;
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    color: var(--text);

    @include onMobile {
      padding-right: 7px;
      font-size: 14px;
      line-height: 25px;
      font-weight: 500;
    }
  }

  .slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 51px;
    border-radius: 34px;
    background: rgba(120, 120, 128, 0.32);
    transition: all 0.2s ease;

    &::before {
      content: '';
      width: 23px;
      height: 23px;
      position: absolute;
      left: 0;
      transform: translateX(5px);
      top: calc(50% - 23px / 2);
      border-radius: 50%;
      background-color: #ffffff;
      border: 0.5px solid rgba(0, 0, 0, 0.04);
      box-shadow: 0px 4px 4px 0px #00000040;
      transition: all 0.2s ease;

      @include onMobile {
        width: 18px;
        height: 18px;
        top: calc(50% - 19px / 2);
      }
    }
  }
}
