@keyframes dots-loader {
  to {
    opacity: 0.1;
  }
}

.DotsLoader {
  display: flex;
  justify-content: center;

  &.pink {
    & > div {
      background-color: #f8585c;
    }
  }

  & > div {
    width: 12px;
    height: 12px;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: #898989;
    opacity: 1;
    animation: dots-loader 0.6s infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}
