@import '../../../assets/styles/variables';

.checkbox {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  border: 2px solid #666666;
}

.Options {
  width: 100%;

  .info {
    margin-top: 18px;
    // padding-left: 10px;
    margin-left: 10px;
    padding: 0;
    background-color: transparent;
    line-height: 150%;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: var(--text);
    border: none;
  }

  .list {
    position: relative;
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 16px;
    margin: 0;
    padding: 0;
    overflow-y: auto;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 10px;
      padding-left: 10px;

      .deleteIcon {
        cursor: pointer;
      }

      .optionTitleContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 11px;
        flex: 1;
        font-weight: bold;

        img {
          width: 26px;
          height: 26px;
        }

        .optionInput {
          width: 100%;
          padding: 0;
          padding-right: 10px;
          font-size: 22px;
          font-weight: 400;
          line-height: 33px;
          text-align: left;
          color: var(--black);
          border: none;
          background-color: transparent;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
