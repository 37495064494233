@import '../../assets/styles/mixins';

.VideoPlayerOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(17, 17, 17, 0.4);
  border-radius: 10px;
  overflow: hidden;

  @include onMobile {
    background-color: transparent;
    border-radius: 0;
  }
}
