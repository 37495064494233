@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.MeetingVideoPlayer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: calc(100vh / var(--zoom) - var(--header-height) - 63px - 70px);
  height: calc(
    100vh / var(--zoom) - var(--header-height) - 63px - 70px - 300px
  );
  border-radius: 10px;
  overflow: hidden;

  @include onMobile {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    border-radius: 0;
  }

  &:hover {
    .controlsOverlay {
      display: flex;
    }
  }

  .controlsOverlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    height: 100%;

    @include onMobile {
      display: flex;
    }
  }

  header {
    display: flex;
    column-gap: 20px;
    position: absolute;
    top: 16px;
    left: 18px;
    z-index: 2;

    .backButton {
      display: flex;
      align-items: center;
      padding: 0;
      background-color: transparent;
      border: none;
    }

    h1 {
      margin-bottom: 0;
      color: #ffffff;
      font-size: 24px;
    }
  }

  .sectionsVisibilityButton {
    position: absolute;
    bottom: 25px;
    padding: 0;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: white;
    background-color: transparent;
    border: none;

    @include onMobile {
      display: none;
    }
  }

  video {
    display: block;
    width: 100%;
    height: 100%;

    //  min-height: 440px;
    object-fit: contain;
    background-color: black;

    @include onMobile {
      height: 100%;
      width: 100%;
    }
  }

  .waveFormContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $primary;
    z-index: 1;
  }
}
