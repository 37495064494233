@import '../../assets/styles/variables';

.ForgotPasswordPage {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    width: 100%;
    max-width: 702px;
    height: 100%;

    .buttonContainer {
      display: flex;
      justify-content: flex-end;
    }
  }

  .signIn {
    margin-bottom: 64px;
    align-self: flex-end;

    a {
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      text-align: right;
      text-decoration: none;
      color: $pink;
    }
  }

  h1 {
    margin-bottom: 82px;
    align-self: flex-start;
    font-weight: 700;
    font-size: 72px;
    line-height: 108px;
    color: $primary;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 56px;

    .row {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .forgotPassword {
        margin: -21px 0 0;
        align-self: flex-start;
        font-size: 20px;
        line-height: 35px;
        color: #888888;
      }
    }
  }

  .info {
    margin-top: -32px;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;

    .email {
      display: block;
      margin-bottom: 32px;
      color: $pink;
    }

    p {
      margin: 0;

      span {
        color: $pink;
        cursor: pointer;
      }
    }
  }
}
