@import '../../../assets/styles/mixins';

.Header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px;
  padding-left: 32px;
  padding-right: 35px;

  @include onMobile {
    padding-top: 16px;
    padding-left: 18px;
    padding-right: 20px;
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    gap: 19px;

    @include onMobile {
      gap: 12px;
    }
  }

  .title,
  button {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    color: white;

    &.shareButton {
      display: flex;
      align-items: center;
    }

    @include onMobile {
      font-size: 16px;
      line-height: 24px;

      img {
        height: 16px;
      }

      &.shareButton {
        display: none;
      }
    }
  }

  button {
    display: flex;
    gap: 14px;
    padding: 0;
    background-color: transparent;
    border: none;
  }
}
