@import '../../../assets/styles/_variables.scss';
@import '../../../assets/styles/_mixins.scss';

.NavBar {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 10px;
  margin: 0;
  padding: 0;

  @include onMobile {
    gap: 4px;
  }

  .title {
    transition: all 0.3s;
  }

  &.collapsed {
    .title {
      opacity: 0;
    }
  }

  a {
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
    text-decoration: none;
    color: $textGray;
    white-space: nowrap;

    @include onMobile {
      font-size: 18px;
      line-height: 27px;
    }

    &.active {
      color: $red;
      background-color: #f8f8f8;

      .iconContainer {
        background: linear-gradient(
          160.04deg,
          #fdb258 -65.33%,
          #f5658c 83.85%,
          #f8585c 233.04%
        );
        border: none;
      }
    }

    &:hover {
      background-color: #f8f8f8;
    }

    li {
      display: flex;
      align-items: center;
      gap: 20px;
      height: 86px;
      padding-left: 29px;

      @include onMobile {
        height: 64px;
        gap: 14px;
        padding-left: 15px;
      }
    }

    .iconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      min-width: 52px;
      max-width: 52px;
      height: 52px;
      border: 2px solid $textGray;
      border-radius: 50%;

      @include onMobile {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        height: 40px;
        border-width: 1.2px;

        &.MyVideos,
        &.Settings {
          border: none;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      img {
        @include onMobile {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
