@import './assets/styles/variables';
@import './assets/styles/mixins';

:root {
  --header-height: 143px;
  --zoom: 0.9;

  // Colors
  --primary: #f8585c;
  --pink: #f5658c;
  --yellow: #fdb258;
  --text: #666666;
  --black: #111111;
}

* {
  box-sizing: border-box;
}

html {
  zoom: var(--zoom);
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.tooltip {
  font-family: 'Poppins', sans-serif;

  &.show {
    opacity: 1;
  }
}

/* .tooltip-inner {
  max-width: 360px;
  padding: 24px 16px;
  text-align: left;
  border-radius: 16px;
  font-size: 24px;
  line-height: 121%;
  background-color: #ffffff;
  color: $primary;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

  p {
    margin: 0;
  }
}

.tooltip-arrow {
  visibility: hidden;
}
 */

.tooltip-inner {
  padding-top: 14px;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 14px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  border-radius: 8px;
}

.bs-tooltip-top {
  .tooltip-arrow {
    left: 15px !important;
    transform: scale(1.5) !important;
  }
}

@media (max-width: 1919px) {
  :root {
    --zoom: 0.85;
  }

  .tooltip {
    pointer-events: none !important;
    visibility: hidden;
  }

  .modal-backdrop {
    width: 100%;
    height: 100%;
    transform: none !important;
  }
}

@media (max-width: 1599px) {
  :root {
    --zoom: 0.75;
  }
}

@media (max-width: 1439px) {
  :root {
    --zoom: 0.65;
  }
}

@media (max-width: 1279px) {
  :root {
    --zoom: 0.55;
  }
}

@media (max-width: 768px) {
  :root {
    --zoom: 1;
  }
}
