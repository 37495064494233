@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.UnderlinedInput {
  flex: 1 1;
  position: relative;
  background-repeat: no-repeat;
  background-position: top right;
  border-bottom: 1px solid $primary;

  &.email {
    background-image: url(../../../assets/images/auth/email.svg);
    background-size: 27px;
    padding-right: 37px;
  }

  &.password {
    background-image: url(../../../assets/images/auth/eye.svg);
    background-size: 31px;
    padding-right: 37px;
  }

  input {
    width: 100%;
    height: 46px;
    padding-bottom: 12px;
    border: none;

    font-weight: 400;
    font-size: 23px;
    line-height: 48px;
    color: $text;
    transition: border-bottom-color 0.2s;

    @include onMobile {
      height: 40px;
      padding-bottom: 8px;
      font-size: 24px;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #ffffff inset;
    }

    &:focus {
      outline: none;
    }

    &:-webkit-autofill ~ label,
    &:focus ~ label,
    &.active ~ label,
    &.error ~ label {
      font-size: 20px;
      line-height: 30px;
      transform: translateY(-36px);
      // color: rgba(0, 0, 0, 0.5);
      color: #666666;
    }

    &.error ~ label {
      font-size: 20px;
      line-height: 30px;
      color: $yellow;
    }

    &.error {
      border-bottom-color: $yellow;
    }

    &:focus ~ .passwordStrength {
      opacity: 100%;
    }
  }

  label {
    position: absolute;
    left: 2px;
    bottom: 10px;
    pointer-events: none;
    font-weight: 400;
    font-size: 36px;
    line-height: 100%;
    color: rgba(0, 0, 0, 0.2);
    transition: all 0.2s;

    @include onMobile {
      font-size: 24px;
    }
  }

  .passwordStrength {
    display: flex;
    column-gap: 8px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    opacity: 0;
    transition: opacity 0.2s;

    span {
      line-height: 112%;
      color: #d9d9d9;

      &.error {
        color: $yellow;
      }

      &.success {
        color: #00d32f;
      }
    }
  }
}
