@import '../../../assets/styles/variables';

.Answer {
  display: flex;
  flex-direction: column;
  gap: 22px;

  .header {
    display: flex;

    h1 {
      margin-bottom: 0;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      text-align: left;
      color: var(--black);
    }

    h2 {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: var(--text);
    }
  }

  .userpic {
    width: 62px;
    min-width: 62px;
    height: 62px;
    margin-right: 16px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: var(--black);
  }
}
