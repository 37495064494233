@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.Subfolder {
  height: 92px;
  width: 100%;
  padding-left: 27px;
  padding-right: 11px;
  display: flex;
  align-items: center;
  border: 1px solid #dddddd;
  border-radius: 5px;
  cursor: pointer;

  @include onMobile {
    height: unset;
    padding-top: 8px;
    padding-left: 12px;
    padding-right: 6px;
    padding-bottom: 8px;
  }

  &:hover {
    background: #f8f8f8;

    @include onMobile {
      background: unset;
    }
  }

  .titleContainer {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: var(--black);

    .newVideosAdded {
      position: absolute;
      font-size: 14px;
      font-weight: bold;
      color: $yellow;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 52px;
      width: 52px;
      height: 52px;
      margin-right: 13px;
      background: linear-gradient(
        160.04deg,
        #fdb258 -65.33%,
        #f5658c 83.85%,
        #f8585c 233.04%
      );
      border-radius: 50%;

      @include onMobile {
        min-width: 36px;
        width: 36px;
        height: 36px;
        background: linear-gradient(
          160.04deg,
          #fdb258 0%,
          #f5658c 83.85%,
          #f8585c 100%
        );
      }

      img {
        @include onMobile {
          width: 20px;
          height: 20px;
        }
      }
    }

    .publishedDate {
      display: none;
    }
  }

  .actionsContainer {
    display: flex;
    margin-left: auto;
  }
}
