@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.Navigation {
  width: 100%;
  padding: 32px 0;
  display: none;
  justify-content: center;
  position: relative;
  background: #f9f9f9;

  @include onMobile {
    display: flex;
    padding-top: 26px;
    padding-bottom: 30px;
    background-color: #ffffff;
  }

  .linksContainer {
    display: flex;
    column-gap: 48px;

    @include onMobile {
      width: 100%;
      max-width: 305px;
      padding-left: 32px;
      padding-right: 32px;
      justify-content: space-between;
      column-gap: 24px;
    }

    a {
      width: 180px;
      position: relative;
      text-align: center;
      font-weight: 600;
      font-size: 22px;
      line-height: 64%;
      text-transform: uppercase;
      color: $gray;
      text-decoration: none;

      @include onMobile {
        width: auto;
        font-size: 18px;
        line-height: 24px;
      }

      &.active {
        color: $red;

        &::after {
          content: '';
          height: 2px;
          width: 100%;
          display: block;
          position: absolute;
          bottom: -8px;
          background: $red;
          opacity: 0.8;
        }
      }
    }
  }
}
