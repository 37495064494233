.ConfirmButton {
  position: relative;
  border: none;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 34px 34px -18px rgba(0, 76, 189, 0.08);
  transition: opacity 0.2s;

  &.withWidth {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
