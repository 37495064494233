@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.Option {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
  padding-left: 37px;
  font-size: 22px;
  line-height: 33px;
  color: $text;
  background-image: url('../../assets/images/uncheckedRadio.svg');
  background-repeat: no-repeat;
  background-position: center left 0;
  cursor: pointer;

  @include onMobile {
    margin-bottom: 0;
    padding-left: 30px;
    font-size: 18px;
    line-height: 27px;
    background-size: 21px;
  }

  &.active {
    background-image: url('../../assets/images/checkedRadio.svg');
  }
}
