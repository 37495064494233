@import '../../assets/styles/variables';

.dialog {
  max-width: 937px;
}

.backdrop {
  background-color: var(--black) !important;
  opacity: 0.7 !important;
}

.RequestReviewModal {
  position: relative;
  padding-top: 44px;
  padding-left: 58px;
  padding-right: 58px;
  padding-bottom: 55px;
  border-radius: 10px;
  background-color: white;

  .closeIcon {
    position: absolute;
    top: 61px;
    right: 59px;
    cursor: pointer;
  }

  header {
    margin-bottom: 22px;

    h1 {
      margin: 0;
      font-weight: 600;
      font-size: 48px;
      line-height: 72px;
      color: var(--black);
    }
  }

  .form {
    margin-bottom: 38px;

    .row {
      display: flex;

      &.inviteRow {
        margin-bottom: 45px;
        gap: 38px;
      }

      .col {
        flex: 1;

        .title {
          h2 {
            margin-bottom: 10px;
            display: flex;
            gap: 10px;
            font-size: 24px;
            line-height: 36px;
            color: var(--black);

            .infoIconContainer {
              font-weight: normal;
              font-size: unset;
            }
          }

          hr {
            margin: 0;
            margin-bottom: 12px;
          }
        }
      }

      .error {
        padding-left: 5px;
        color: $red;
      }
    }

    .messageContainer {
      position: relative;
    }
  }
}
