@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.MeetingsPage {
  min-height: calc(100vh / var(--zoom) - var(--header-height));

  .breadcrumbsContainer {
    margin-bottom: 52px;

    @include onMobile {
      margin-bottom: 0;
      padding-bottom: 16px;
    }

    .title {
      margin: 0;
      font-weight: 600;
      font-size: 26px;
      line-height: 125%;
      color: $text;

      @include onMobile {
        display: none;
      }
    }
  }

  .container {
    min-height: calc(100vh / var(--zoom) - 204px);
    padding: 16px 72px;
    padding-left: 42px;
    padding-right: 42px;

    @include onMobile {
      min-height: calc(100vh / var(--zoom) - 244px);
      padding-left: 20px;
      padding-right: 20px;
    }

    button {
      padding: 0;
      background-color: transparent;
      border: none;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .foldersContainer {
      display: flex;
      gap: 0;
      margin-bottom: 64px;

      @include onMobile {
        flex-direction: column;
        gap: 13px;
        margin-bottom: 15px;
      }
    }

    .tableContainer {
      display: flex;
      justify-content: center;

      .table {
        width: 100%;
        // table-layout: fixed;

        tr {
          position: relative;
        }

        thead tr,
        tbody tr:not(:last-of-type) {
          &:not(:hover) {
            &::after {
              content: '';
              height: 1px;
              display: block;
              position: absolute;
              left: 8px;
              right: 8px;
              bottom: 0;
              background-color: rgba(0, 0, 0, 0.05);

              @include onMobile {
                display: none;
              }
            }
          }
        }

        thead {
          height: 50px;

          @include onMobile {
            display: none;
          }

          tr {
            th {
              padding: 0 6px 12px;
              padding-bottom: 28px;
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              color: var(--black);
              cursor: pointer;

              &:first-child {
                padding-left: 0;
              }

              .sortOrder {
                .chevron {
                  width: 15px;
                  margin-left: 2px;
                }

                &.asc {
                  .chevron {
                    position: relative;
                    top: -2px;
                    transform: rotate(180deg);
                  }
                }

                &.desc {
                  .chevron {
                    position: relative;
                    top: 0px;
                  }
                }
              }
            }

            .searchBarContainer {
              padding-right: 20px;
              display: flex;
              flex-direction: row-reverse;
            }
          }
        }

        tbody {
          tr {
            position: relative;

            &:hover::after {
              content: '';
              width: 100%;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              background-color: #f9f9f9;
              z-index: -1;

              @include onMobile {
                display: none;
              }
            }

            td {
              position: relative;
              min-height: 120px;
              padding: 8px 6px 8px;
              font-weight: 500;
              font-size: 24px;
              line-height: 36px;
              color: var(--text);

              @include onMobile {
                height: unset;
                padding: 0;
                padding-top: 25px;
                padding-bottom: 25px;
                border-bottom: 1px solid #d9d9d9;
              }

              &.capitalize {
                text-transform: capitalize;
              }

              &.mobileActionsMenu {
                width: 20px;
                padding-left: 15px;
              }

              &.title {
                height: 100%;
                padding-left: 15px;
                margin-left: 32px;
                //  display: flex;
                //   align-items: center;
                //  justify-content: center;

                @include onMobile {
                  min-height: 70px;
                  margin: 0;
                  padding-left: 0;
                  padding-right: 0;
                  display: flex;
                  justify-content: space-between;
                }

                div {
                  display: flex;
                  align-items: center;
                }

                a {
                  text-decoration: none;
                  color: $text;

                  @include onMobile {
                    font-weight: 600;
                    font-size: 18px;
                  }

                  &:hover {
                    text-decoration-line: underline;
                    color: $pink;

                    @include onMobile {
                      text-decoration-line: none;
                      color: unset;
                    }
                  }
                }

                .titleText {
                  @include onMobile {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                  }
                }

                .roundIcon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 55px;
                  height: 55px;
                  min-width: 55px;
                  border-radius: 50%;
                  border: 2px solid black;

                  @include onMobile {
                    width: 36px;
                    height: 36px;
                    min-width: 36px;
                    border-width: 1.5px;

                    img {
                      width: 70%;
                      height: 70%;
                    }
                  }
                }

                .videoIcon {
                  margin-right: 0 !important;
                  @include onMobile {
                    display: table-cell;
                    height: 36px;
                    width: 36px;
                    margin-right: 8px;
                  }
                }

                .left {
                  display: flex;
                  gap: 16px;

                  @include onMobile {
                    width: 100%;

                    .titleContainer {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                    }
                  }
                }

                .right {
                  display: flex;
                  gap: 4px;

                  button {
                    display: none;

                    @include onMobile {
                      display: flex;
                      margin-left: auto;
                      background: transparent;
                      border: none;

                      img {
                        width: 36px;
                        height: 36px;
                      }
                    }
                  }
                }

                .publishedDate {
                  display: none;

                  @include onMobile {
                    display: block;
                    padding-left: 0 !important;
                    font-size: 14px;
                    line-height: 18px;
                  }
                }
              }

              &.duration {
                text-align: left;
                min-width: 80px;

                button {
                  width: 57px;
                  height: 57px;
                  margin-right: 16px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .noDuration {
                  opacity: 0.3;
                }

                @include onMobile {
                  display: none;
                }
              }

              &.owner {
                @include onMobile {
                  display: none;
                }
              }

              &.status {
                @include onMobile {
                  display: none;
                }
              }

              &.comment {
                max-width: 120px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @include onMobile {
                  display: none;
                }
              }

              &.buttons {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: flex-end;
                gap: 17px;

                @include onMobile {
                  display: initial;
                }

                .button {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  min-width: 55px;
                  width: 55px;
                  height: 55px;
                  border: 2px solid black;
                  border-radius: 50%;
                }

                .actionButton:not(.button) {
                  min-width: 57px;
                  width: 57px;
                  height: 57px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .buttonsContainer {
                  display: flex;
                  flex-direction: row-reverse;
                  align-items: center;
                  gap: 24px;
                }
              }
            }

            /* &.clickable {
              cursor: pointer;
              position: relative;

              &:hover::after {
                content: '';
                height: 100px;
                display: block;
                position: absolute;
                top: 10px;
                left: -30px;
                right: 0;
                background-color: #f9f9f9;
                z-index: -1;
                border-radius: 40px;

                @include onMobile {
                  display: none;
                }
              }
            } */
          }
        }
      }
    }

    .noMeetings {
      margin: 0;
      position: absolute;
      top: 65%;
      right: 50%;
      transform: translateX(50%);
      text-align: center;
      font-weight: 400;
      font-size: 42px;
      line-height: 114%;
      color: $text;

      @include onMobile {
        position: unset;
        top: unset;
        right: unset;
        transform: unset;
        margin: 32px 0;
        font-size: 32px;
      }
    }

    .paginationContainer {
      display: flex;
      justify-content: flex-end;

      @include onMobile {
        justify-content: center;
      }

      .buttonsList {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;

        &.disabled {
          opacity: 0.3;

          a {
            cursor: not-allowed;
          }
        }

        &.active {
          a {
            width: 24px;
            text-align: center;
            font-family: 'Inter';
            line-height: 125%;
            border-radius: 4px;
            background-color: $red;
            color: #ffffff;
          }
        }

        a {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 125%;
          text-decoration: none;
          color: $text;
        }
      }
    }
  }
}
