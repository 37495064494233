@import '../../assets/styles/mixins';

.Checkbox {
  &.big {
    .checkmark {
      transform: scale(1.6);
    }
  }

  .label {
    color: var(--text);
  }

  /* Customize the label (the container) */
  .container {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;

    @include onMobile {
      padding-left: 33px;
    }

    &.disabled {
      cursor: auto;
    }
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: calc(50% - 12.5px);
    left: 0;
    height: 25px;
    width: 25px;
    background-image: url('../../assets/images/uncheckedCheckbox.svg');
    background-repeat: no-repeat;
    background-position: center;

    @include onMobile {
      width: 21px;
      height: 21px;
      top: calc(50% - 10.5px);
    }
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-image: url('../../assets/images/checkedCheckbox.svg');

    &.disabled {
      background: #ffffff;
      cursor: not-allowed;
    }
  }
}
