.MobileHeader {
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-top: 17px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 19px;

    &:last-child {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .mobileMenusTitle {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    font-weight: 600;
    color: var(--black);
    text-transform: uppercase;
  }

  .burgerButton {
    width: 26px;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .searchBarContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 61px;
    background-color: var(--primary);
  }

  .logo {
    width: 100%;
    max-width: 182px;
    height: auto;
  }

  .mobileNavBar {
    position: fixed;
    z-index: 99999999;
    top: 77px;
    left: 0;
    right: 0;
    height: calc(100dvh - 77px);
    background-color: white;
  }
}
