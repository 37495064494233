@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.UploadButton {
  display: none;

  @include onMobile {
    display: block;
    position: fixed;
    z-index: 9999999;
    bottom: 34px;
    right: 17px;
    width: 60px;
    height: 60px;
    background-color: var(--primary) !important;
    border: none;
    border-radius: 50%;
  }
}
