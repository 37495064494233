@import '../../assets/styles/variables';

.backdrop {
  background-color: var(--black) !important;
  opacity: 0.7 !important;
}

.dialog {
  max-width: min(calc(100vw / var(--zoom) - 80px), 1435px);
  padding-right: 0px !important;
}

.contentContainer {
  border-radius: 10px;
  overflow: hidden;
}

.SummaryViewerReportModal {
  padding-right: 0 !important;
}
