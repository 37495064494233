@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.RoundedInput {
  width: 100%;
  position: relative;

  &.readOnly {
    label {
      transform: translateY(-54px);
      font-weight: 500;
      font-size: 14px;
      line-height: 171%;
    }
  }

  input {
    width: 100%;
    height: 77px;
    padding-left: 33px;
    padding-right: 33px;
    border: none;
    border-radius: 60px;
    font-size: 22px;
    line-height: 33px;
    //  box-shadow: 0px 16px 34px -18px rgba(0, 76, 189, 0.08);
    color: var(--black);
    border: 1px solid var(--text);

    @include onMobile {
      height: 50px;
      font-size: 16px;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 60px white inset;
    }

    &::placeholder {
      color: var(--black);
      opacity: 0.4;
    }

    &:disabled {
      text-align: center;
      background-color: #ffffff;
    }

    &:focus {
      outline: none;
    }

    &:focus ~ label,
    &.active ~ label,
    &.error ~ label {
      font-size: 20px;
      line-height: 30px;
      transform: translateY(-54px);

      @include onMobile {
        transform: translateY(-34px);
      }
    }

    &.error ~ label {
      color: $yellow;
    }

    &.error {
      border-color: $yellow;
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 22.5px;
    transform: translateY(-50%);
    display: inline-block;
    padding-left: 11px;
    padding-right: 11px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: rgba(102, 102, 102, 0.3);
    background-color: white;
    pointer-events: none;
    transition: all 0.2s ease;
    opacity: 1;

    @include onMobile {
      font-size: 14px !important;
      line-height: 16px !important;
    }
  }
}
