@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.FilledButton {
  min-width: 120px;
  height: 66px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 7px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  background-color: $red;
  color: #ffffff;

  @include onMobile {
    min-width: 100px;
    height: 52px;
    padding: 12px;
    //  border-radius: 20px;
    font-size: 18px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
